/*! 
 * (C)Mynavi Corporation
 */
/** ============================================================
 * INDEX
 * ========================================================== */

import Cookies from 'js-cookie';

var MNI = MNI || {};


MNI.swiper = function () {
  /** ------------------------------
   * swiper
   * ---------------------------- */

  var itemName = '.js--sliderTop--pc';
  if (window.mnstate.isMobile == true) {
    itemName = '.js--sliderTop--sp';
  }

  var slideItemAll = document.querySelectorAll(itemName + ' .swiper-slide');
  if (window.mnstate.isMobile == true) {
    slideItemAll = document.querySelectorAll(itemName + ' .swiper-slide');
  }

  var slideItemAllNum = slideItemAll.length;

  // PC個別設定：PC時にブロックが１つしか無い場合はスライダー無し
  if (window.mnstate.isMobile == false) {
    if (slideItemAllNum <= 1) {
      return;
    }else{
      // ブロックが１つ以上ある場合は表示クラスを追加
      document.getElementsByClassName('js--sliderTop--pc')[0].classList.add('p--move__slider');
    }
  }

  var topSwiper = new Swiper(itemName + ' .swiper-container', {
    autoplay: {
      delay: 5000,
      stopOnLastSlide: false,
      disableOnInteraction: false,
      reverseDirection: false
    },
    navigation: {
      nextEl: itemName + ' .swiper-button-next',
      prevEl: itemName + ' .swiper-button-prev',
    },
    pagination: {
      el: itemName + ' .swiper-pagination',
      type: 'bullets',
      clickable: true
    },
    slidesPerView: 1,
    initialSlide: slideItemAllNum,
    loop: true,

  });

}


/** ============================================================
 * タブ切り替え
 * ========================================================== */
/** ------------------------------
 * タブコンテンツ
 * ---------------------------- */
MNI.tabContents = function () {

  var elTablist = document.querySelectorAll('.js--tab_label');
  var select_area = Cookies.get('area');

  /* NodeList.prototype.forEachが使えないIEなどへの配慮 */
  if (!NodeList.prototype.forEach) {
    NodeList.prototype.forEach = Array.prototype.forEach;
  }
  elTablist.forEach(function (element, i) {
    // selectArea(element);
    element.addEventListener('click', tabfunc);
  });

  function selectArea(e) {

    // タブをすべて
    var targetParent = e; //タブの親
    var targets = targetParent.children; //タブの中身全て(見出しも入ってくる)
    var elContents = targetParent.nextElementSibling;//タブの次のグループ
    var elPanels = elContents.children;//次のグループの子供全て（コンテンツ）

    for(var i = 0; i < targets.length; i++){
      var tabArea = targets[i].classList.contains('p--area');
      if(tabArea == true){

        var tabAreaChild = targets[i].children;

        if(!select_area){
          tabAreaChild[0].setAttribute('aria-selected', true);
          elPanels[0].setAttribute('aria-hidden', false);
        }else{
          for(var num = 0; num < tabAreaChild.length; num++){
            var selectChild = tabAreaChild[num].dataset.tabArea;
            if(select_area == selectChild){
              tabAreaChild[num].setAttribute('aria-selected', true);
              elPanels[num].setAttribute('aria-hidden', false);
            }else{
              tabAreaChild[num].setAttribute('aria-selected', false);
              elPanels[num].setAttribute('aria-hidden', true);
            }
          }
        }
      }
    }
  };


  function tabfunc(e) {
    var targetParent = e.target.parentNode;//イベントが発火したタブの親
    var targets = targetParent.children;//タブを全て
    var elContents = targetParent.parentNode.nextElementSibling;//タブの次のグループ
    var elPanels = elContents.children;//次のグループの子供全て（コンテンツ）
    var targetIdx =Array.prototype.indexOf.call(targets, e.target);//イベントのあったタブのインデックス

    for(var i = 0; i < targets.length; i++){
      if(i == targetIdx) {
        targets[i].setAttribute('aria-selected', true);
        elPanels[i].setAttribute('aria-hidden', false);
        // cookieに保存するエリア名
        var setArea = targets[i].dataset.tabArea;
      } else {
        targets[i].setAttribute('aria-selected', false);
        elPanels[i].setAttribute('aria-hidden', true);
      }
    }
    // Cookieに状態保存 選択されていなければ空にする
    Cookies.remove('area');
    if(setArea){
      Cookies.set('area', setArea, {
        expires: 30
      });
    }

  };
}





/** ------------------------------
 * 追従ボタンエリア
 * ---------------------------- */
MNI.fixedBnrForSp = function (mobileState) {

  var $f_bnr = $('#js--top__fixedBnr');
  var $ptBtn = $('#js--pageTop');

  //スクロール時の動作をページ読み込み時にも
  scrollEvent();

  //スクロール時の動作
  $(window).on("scroll", scrollEvent);

  //スクロール時の動作関数
  function scrollEvent() {

    //ボタンのフェードインフェードアウト
    if ($(window).scrollTop() > 100) {
      $f_bnr.css({
        'opacity': 1,
        'visibility': 'visible',
        'bottom': 0
      }).fadeIn("fast");
    } else {
      $f_bnr.fadeOut("fast", function() {
        $(this).css({
          'opacity': 0,
          'visibility': 'hidden'
        });
      });
    }

  }

  // クリックしたら閉じる
  $('.js--top__fixedBnr--close').click(function() {
    $('#js--top__fixedBnr').fadeOut('fast').queue(function() {
      $('#js--top__fixedBnr').remove();
    });
    $ptBtn.css({"bottom": "20px"});
  });

}

/* ////////////////////////////////////////////////////////////////////////////////////////// */
/* ////////////////////////////////////////////////////////////////////////////////////////// */
/** ============================================================
 * 要素が全て読み込まれたときの処理
 * ========================================================== */
window.addEventListener('load', function(){
  MNI.tabContents(); // タブ切り替え
  MNI.swiper(); // スライダー

  if (window.mnstate.isMobile == true) {
    MNI.fixedBnrForSp(); //下にバナー表示(SPのみ)
  }
}, false);




